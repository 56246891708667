import { useState, useEffect } from "react";
import {
  getGeocode,
  getZipCode,
  getLatLng,
  GeocodeResult,
} from "use-places-autocomplete";
import { GoogleGeocodeLocation, Place } from "utils/googleMapsUtils";
import Image from "next/image";
import locationImg from "@/img/location.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';


interface AutocompleteCustomProps {
  setZipcode: (zipcode: string | number) => void;
  setValue: (value: string, shouldFetch?: boolean) => void;
  clearSuggestions: () => void;
  // eslint-disable-next-line no-undef
  data: google.maps.places.AutocompletePrediction[];
}

const useAutocompleteCustom = ({
  setZipcode,
  setValue,
  clearSuggestions,
  data,
}: AutocompleteCustomProps) => {
  const [curLocation, setCurLocation] = useState("");
  const [geocodeLocation, setGeocodeLocation] =
    useState<GoogleGeocodeLocation>(null);
  const [selectedLocation, setSelectedLocation] = useState<GeocodeResult>(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        getGeocode({ location: { lat: latitude, lng: longitude } }).then(
          (results) => {
            const address = formatAddress(results[0]);
            setGeocodeLocation(results[0]);
            setCurLocation(address);
          },
        );
      });
    }
  }, [navigator.geolocation]);

  // TODO: Format address.
  // TODO: Add skeleton for when current address isn't know.
  const formatAddress = (geocodeLocation: GoogleGeocodeLocation) => {
    const components = {};
    for (const address_component of geocodeLocation.address_components) {
      if (address_component.types[0] === "route") {
        components["street"] = address_component.long_name;
      } else if (address_component.types[0] === "postal_code") {
        components["zipcode"] = address_component.long_name;
      } else if (address_component.types[0] === "locality") {
        components["city"] = address_component.long_name;
      } else if (address_component.types[0] === "administrative_area_level_1") {
        components["state"] = address_component.short_name;
      }
    }
    const formattedAddress = `${components["street"]}, ${components["city"]}, ${components["state"]} ${components["zipcode"]}`;
    return formattedAddress;
  };

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (place: Place) => () => {
    console.log(place);
    // When the user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(place.description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: place.description }).then((results) => {
      let zipcode = getZipCode(results[0], false);
      const latLng = getLatLng(results[0]);

      console.log("No zipcode found, trying to get it from latLng");
      // eslint-disable-next-line no-undef
      const geoencoder = new google.maps.Geocoder();
      const res = geoencoder.geocode({ location: latLng }).then((response) => {
        for (const address_component of response.results[0]
          .address_components) {
          if (address_component.types[0] === "postal_code") {
            zipcode = address_component.long_name;
            setZipcode(zipcode);
            break;
          }
        }
        setSelectedLocation(response.results[0]);
      });
      setZipcode(zipcode);
    });
  };

  const handleCurLocationSelect = (location: GeocodeResult) => {
    setZipcode(getZipCode(location, false));
    setValue(curLocation, false);
    setSelectedLocation(location);
    clearSuggestions();
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          key={place_id}
          onClick={handleSelect(suggestion)}
          className="bg-white"
        >
          <i className="absolute p-3">
            <Image src={locationImg} alt="" />
          </i>
          <div className="hover:outline-slate-600 h-12 w-full overflow-hidden border border-gray-600 pl-12 focus:border-0 focus:border-transparent focus:outline-blue">
            <div>
              <strong>{main_text}</strong>
              <br />
              <small>{secondary_text}</small>
            </div>{" "}
            {/* <div className=" pb-1"></div> */}
          </div>
        </div>
      );
    });

  const CurLocationSuggestion = () => {
    return (
      <div
        key={"current-location-suggestion"}
        onClick={curLocation ? () => handleCurLocationSelect(geocodeLocation) : undefined}
        className="bg-white"
        onKeyDown={({ key }) => console.log("submit")}
      >
        <i className="absolute p-3">
          <FontAwesomeIcon
            icon={faLocationArrow}
            className="w-6 h-6"
          />
        </i>
        <div
          onKeyDown={({ key }) => console.log("submit")}
          className="hover:outline-slate-600 h-12 w-full overflow-hidden border border-gray-600 pl-12 focus:border-0 focus:border-transparent focus:outline-blue"
        >
          <div>
            <strong>{`Use Current Location`}</strong>
            <br />
            <small>{curLocation ? curLocation : "Loading..."}</small>
          </div>{" "}
          {/* <div className=" pb-1"></div> */}
        </div>
      </div>
    );
  };

  return {
    handleInput,
    renderSuggestions,
    CurLocationSuggestion,
    selectedLocation,
  };
};

export default useAutocompleteCustom;
